.salee_logo {
    border-radius: 10px;
    height: 60px;
    width: 60px;
    vertical-align: bottom;
    display: inline;
    margin-left: 20px;
    margin-top: 20px;
}

.apple_logo {
    height: 60px;
    width: 150px;
    vertical-align: bottom;
}

.ant-menu-horizontal > .ant-menu-item a:hover {
  color: var(--saleeBrand);
}

.ant-menu-submenu-title:hover {
  color: var(--saleeBrand);
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: var(--saleeBrand);
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-title:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-title,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-only-child:hover {
  color: var(--saleeBrand);
  border-bottom: 2px solid var(--saleeBrand);
}

.ant-menu-horizontal > .ant-menu-item-selected a {
  color: var(--saleeBrand);
}

.ant-menu-horizontal {
  border-bottom: 0px;
  padding-bottom: 20px;
}

.ant-menu {
  font-size: 18px;
}

.ant-menu-item-selected a, .ant-menu-item-selected a:hover {
  color: var(--saleeBrand);
}

.ant-menu-item a:hover {
  color: var(--saleeBrand);
}
