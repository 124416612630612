.hero-image {
  background-image: url("pexels-jeffrey-czum-2904142.jpg");
  height: 90vh;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero-text {
  text-align: center;
  position: relative;
  top: 150px;
  color: white;
  font-size: 400%;
  font-weight: 700;
}

.ant-input-lg {
  border-radius: 8px;
  font-size: 20px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.ant-input:hover {
  border-color: var(--saleeBrand);
}

.ant-input:focus, .ant-input-focused {
  border-color: var(--saleeBrand);
}

.ant-btn-primary {
  background: var(--saleeBrand);
  border-color: var(--saleeBrand);
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
  background: var(--saleeBrand);
  border-color: var(--saleeBrand);
}


/* Search page */
.hidden {
  display: none;
}
.gutter-box {
  padding: 20px;
  background: white;
}

.horizontal-scroll {
  overflow: auto;
  white-space: nowrap;
}

/* Property page */
.ant-image {
  margin: 10px 2px 10px;
}

a {
  color: var(--saleeBrand);
}
